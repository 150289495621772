import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const CaptionTitle = makeShortcode("CaptionTitle");
const ImageCaption = makeShortcode("ImageCaption");
const Dont = makeShortcode("Dont");
const ImageWrapper = makeShortcode("ImageWrapper");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Error messages</Heading>
      <Text mdxType="Text">
  Handled with care, errors present an opportunity to strengthen our
  relationship with people who use our products. Consistent in-app support
  fosters trust and credibility.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">A well-designed error message</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Reduces confusion by making a problem easy to understand.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Reassures people by providing a solution they can trust.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Eases frustration, which improves the experience.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/6386ed32-2b1e-4f35-87a7-3d0e02f6d06d" alt="Error message on a task management board, clearly describing an issue." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Be specific and clear to ensure people understand what's happening.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/1128d20f-76de-4f20-966d-dc57ab113d8d" alt="Difficult and generic error message in an undefined place." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Avoid generic messages and technical jargon, which adds to confusion.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Plan ahead</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Consider what can go wrong early on. We might be able to prevent a broken
    experience.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Some errors are inevitable, but don't leave them as afterthoughts.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Make sure you understand the problem so you can explain it to others.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/33876eda-d5a7-4744-aab7-ca2105dc85f0" alt="Text field showing character count within helper text." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Keep people on the happy path with hints and clear helper text.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/080b9acf-be80-49cc-857d-b0563dd81326" alt="Text field not showing character count within helper text." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Even easy-to-fix errors are inconvenient and cause delays.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Say it out loud</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Write it the way you'd explain it to the customer—and never blame them.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Stay brief, on point, and use plain, everyday language to ensure clarity.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Provide a way out.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use the word <i>error</i>, which states the obvious and doesn't add
    value.<br></br>
    Exception: rare cases with detailed logs aimed at highly technical
    audiences.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't say <i>oops</i>, <i>whoopsie</i>, or use other cute language. It's
    redundant and annoying.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use exclamation marks to avoid causing more stress.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't overuse <i>sorry</i> or <i>please</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/c7d84b99-b263-4622-bc0e-b26e78fa9a28" alt="Concise and direct error message with a clear description of the issue and a solution." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Communicating issues directly respects people's time and effort.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/50ec2aa5-516d-4cf0-a50b-05b783c87367" alt="Consise and direct error message that assigns blame to the user." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Keeping it straightforward should never lead to assigning blame.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Match tone to impact</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Some errors are more challenging than others.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When the app encounters a technical issue, provide comfort and reassurance.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use industry terms only if they're the easiest way to understand what's
    wrong.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Surround any specialized terminology with human words.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use logs or error codes unless you know it helps the intended
    audience.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/2fbf1bec-c5db-4344-b532-ed8d3928d703" alt="Easy to read error message that explains app failure, provides reassurance and provides access to error log." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Taking responsibility for the problem shows honesty and builds trust.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/87d060a6-e854-4fc5-9482-94215adf383f" alt="Error message with no explanation or reassurance, showing error log without context." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Most people won't benefit from logs and codes without context.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Anatomy of an error message</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Identify 3 pieces of information: what happened, why it happened, and what
    to do next.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Decide if the context requires all that information and in what order.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For user errors, the most helpful could be a precise explanation of the
    problem.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For app failures, the user might benefit more from reassurance.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If you use a component with a title or heading, ensure it adds value.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the title or heading to say what happened. Skip the word <i>error</i>{" "}
    entirely.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/9aebcaef-3533-4d25-a94d-59905d22865d" alt="4 examples of how error messages can be built using 3 interchangeable and combinable microcopy blocks." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">
  Tip: think of <b>[what happened]</b>, <b>[why it happened]</b>, and{" "}
  <b>[what to do]</b> as building microcopy blocks. Choose the ones that match
  the problem's complexity and arrange them in the most user-friendly sequence.
      </ImageCaption>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Inline error messages</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use them to help users fix slips and mistakes directly within input fields.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Stay brief since inline messages are highly contextual.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Provide an immediate explanation or a prompt on what to do.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Be specific by matching the error („Enter your password”) with the label
    („Password”).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Leave out <i>please</i> and <i>sorry</i> entirely. Those errors are easy to
    fix.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid ambiguous <i>valid</i> and <i>invalid</i>. Instead, explain why the
    input can't be validated.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/18420e03-9443-41ee-bf70-97eb8e14bd32" alt="Inline error message showing how one of the microcopy blocks can be used in practice." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Writing only <b>[what to do]</b> is often the most effective for inline
      messages.
    </ImageCaption>
  </Do>
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/038aa148-2aeb-4f95-8d2c-4f04da91ee6e" alt="Inline error message showing why another block might be a better choice, depending on the context." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Using <b>[why it happened]</b> instead can sometimes be more direct and
      natural.
    </ImageCaption>
  </Do>
      </DoDontComponent>
      <hr />
      <Text mdxType="Text">
  Share your feedback on the #design-systems channel in Slack.
      </Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      